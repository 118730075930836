import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Logo from '../images/wisconsin-picture-framing-shop.svg';

const MenuMobile = props => {
  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <div
      id="main-menu-mobile"
      className={`main-menu-mobile ${props.active ? 'open' : ''}`}
    >
      <div className="m-8"><img src={Logo} /></div>
      <ul>
        {menuLinks.map(link => (
          <li key={link.name} className="button is-info">
            <Link to={link.link} className="has-text-white	">{link.name}</Link>
          </li>
        ))}
        <li className="mt-5"><a href="tel:612-706-6020">Phone: (612) 706-6020</a></li>
      </ul>
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query MenuMobileQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <MenuMobile active={props.active} data={data} />}
  />
);
