import React from 'react';
import { Link } from 'gatsby';
import Menu from './Menu';
import Hamburger from './Hamburger';
import MenuMobile from './MenuMobile';
import Logo from '../images/wisconsin-picture-framing-shop.svg';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
    };
  }

  toggleMenu = menuActive => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive,
    }));
  };

  render() {
    return (
      <>
      <div className="header sticky">
        <div className="container" itemprop="ProfessionalService" itemscope itemtype="http://schema.org/LocalBusiness">
          <div className="logo" itemprop="name">
            <Link to="/">
           <img src={Logo} />
            </Link>
          </div>
          <div className="logo-mobile">
            <Link to="/">
            <img src={Logo} />
            </Link>
          </div>
          <MenuMobile active={this.state.menuActive} />
          <Menu />
          <Hamburger toggleMenu={this.toggleMenu} />
        </div>
      </div>
      </>
    );
  }
}

export default Header;
