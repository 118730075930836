import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
const SubFooter = props => (
  <div className="sub-footer-strip">
    <div className="container">
              <div className="copyright">
                ©{' '}{new Date().getFullYear()}{' '}
                {props.data.site.siteMetadata.title} | <a href="http://localizedpro.com/">Site Design: Localized Pro</a>
              </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={data => <SubFooter data={data} /> }
  />
  
);
