import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

const Footer = props => (
    <div className="has-text-centered p-4 has-text-white	 footer-cta">
          <h2 className="title is-spaced has-text-white	is-size-1	">Ready To Get Something Framed?</h2>
          <p className="subtitle has-text-white">We Frame: Photos, Canvas, Posters, Limited Edition Items, Memorabilia, Mirrors, and More!</p>
<a className="button is-white" href="/get-something-framed">Get Started</a>
    </div>

);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
